import SkillIcon from './SkillIcon';
import insomniaIcon from '../../assets/icons/insomnia.png';
import netlifyIcon from '../../assets/icons/netlify.png';
import apolloIcon from '../../assets/icons/apollo.png';
import mongooseIcon from '../../assets/icons/mongoose.png';
import photoshopIcon from '../../assets/icons/photoshop-plain.svg';
import afterEffectsIcon from '../../assets/icons/aftereffects-original.svg';
import canvaIcon from '../../assets/icons/canva-original.svg';
import facebookIcon from '../../assets/icons/facebook-original.svg';
import illustratorIcon from '../../assets/icons/illustrator-plain.svg';
import linkedinIcon from '../../assets/icons/linkedin-original.svg';
import mayaIcon from '../../assets/icons/maya-original.svg';
import premiereProIcon from '../../assets/icons/premierepro-original.svg';
import wordpressIcon from '../../assets/icons/wordpress-plain.svg';
import slackIcon from '../../assets/icons/slack-original.svg';
import figmaIcon from '../../assets/icons/figma.svg';
import tailwindIcon from '../../assets/icons/tailwind.svg';

export function ReactIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
      iconTitle="React"
      altText="React Icon"
      iconName="React"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function JsIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
      iconTitle="JavaScript"
      altText="JavaScript Icon"
      iconName="JavaScript"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function HtmlIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
      iconTitle="HTML 5"
      altText="HTML 5 Icon"
      iconName="HTML 5"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function CssIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"
      iconTitle="CSS 3"
      altText="CSS 3 Icon"
      iconName="CSS 3"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function TailwindIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={tailwindIcon}
      iconTitle="Tailwind"
      altText="Tailwind Icon"
      iconName="Tailwind"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function BootstrapIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"
      iconTitle="Bootstrap"
      altText="Bootstrap Icon"
      iconName="Bootstrap"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function NodeIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
      iconTitle="Node JS"
      altText="Node JS Icon"
      iconName="Node JS"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function ExpressIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg"
      iconTitle="Express"
      altText="Express Icon"
      iconName="Express"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function MysqlIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"
      iconTitle="MySQL"
      altText="MySQL Icon"
      iconName="MySQL"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function SequelizeIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sequelize/sequelize-original.svg"
      iconTitle="Sequelize"
      altText="Sequelize Icon"
      iconName="Sequelize"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function MongodbIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg"
      iconTitle="MongoDB"
      altText="MongoDB Icon"
      iconName="MongoDB"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function GitIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
      iconTitle="Git"
      altText="Git Icon"
      iconName="Git"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function GraphqlIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain.svg"
      iconTitle="GraphQL"
      altText="GraphQL Icon"
      iconName="GraphQL"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function JestIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg"
      iconTitle="Jest"
      altText="Jest Icon"
      iconName="Jest"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function HerokuIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-original.svg"
      iconTitle="Heroku"
      altText="Heroku Icon"
      iconName="Heroku"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function HandlebarsIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/handlebars/handlebars-original.svg"
      iconTitle="Handlebars JS"
      altText="Handlebars JS Icon"
      iconName="Handlebars JS"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function BabelIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/babel/babel-original.svg"
      iconTitle="Babel"
      altText="Babel Icon"
      iconName="Babel"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function InsomniaIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={insomniaIcon}
      iconTitle="Insomnia"
      altText="Insomnia Icon"
      iconName="Insomnia"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function NetlifyIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={netlifyIcon}
      iconTitle="Netlify"
      altText="Netlify Icon"
      iconName="Netlify"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function ApolloIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={apolloIcon}
      iconTitle="Apollo GraphQL"
      altText="Apollo GraphQL Icon"
      iconName="Apollo GraphQL"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function MongooseIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={mongooseIcon}
      iconTitle="Mongoose"
      altText="Mongoose Icon"
      iconName="Mongoose"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function VscodeIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg"
      iconTitle="VS Code"
      altText="VS Code Icon"
      iconName="VS Code"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function ChromeIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/chrome/chrome-original.svg"
      iconTitle="Chrome Dev Tools"
      altText="Chrome Icon"
      iconName="Chrome Dev Tools"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function GithubIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg"
      iconTitle="GitHub"
      altText="GitHub Icon"
      iconName="GitHub"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function PhotoshopIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={photoshopIcon}
      iconTitle="Photoshop"
      altText="Photoshop Icon"
      iconName="Photoshop"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function AfterEffectsIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={afterEffectsIcon}
      iconTitle="After Effects"
      altText="After Effects Icon"
      iconName="After Effects"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function CanvaIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={canvaIcon}
      iconTitle="Canva"
      altText="Canva Icon"
      iconName="Canva"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function FacebookIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={facebookIcon}
      iconTitle="Facebook"
      altText="Facebook Icon"
      iconName="Facebook"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function IllustratorIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={illustratorIcon}
      iconTitle="Illustrator"
      altText="Illustrator Icon"
      iconName="Illustrator"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function LinkedInIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={linkedinIcon}
      iconTitle="LinkedIn"
      altText="LinkedIn Icon"
      iconName="LinkedIn"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function MayaIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={mayaIcon}
      iconTitle="Maya"
      altText="Maya Icon"
      iconName="Maya"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function PremiereProIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={premiereProIcon}
      iconTitle="PremierePro"
      altText="PremierePro Icon"
      iconName="PremierePro"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function WordPressIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={wordpressIcon}
      iconTitle="WordPress"
      altText="WordPress Icon"
      iconName="WordPress"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function SlackIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={slackIcon}
      iconTitle="Slack"
      altText="Slack Icon"
      iconName="Slack"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}

export function FigmaIcon({ divClassName, iconClassName, showText }) {
  return (
    <SkillIcon
      divClassName={divClassName}
      iconSrc={figmaIcon}
      iconTitle="Figma"
      altText="Figma Icon"
      iconName="Figma"
      iconClassName={iconClassName}
      showText={showText}
    />
  );
}